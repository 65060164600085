import { DepGraph } from "dependency-graph";
import { nanoid } from "nanoid";
import { Edge, Node } from "reactflow";

import { SchemaModelType } from "src/types/schema";

import eventIcon from "./assets/event.svg";
import eventSrc from "./assets/icons/event.svg";
import relatedSrc from "./assets/icons/related.svg";
import parentIcon from "./assets/parent.svg";
import relatedIcon from "./assets/related.svg";
import { GraphModel, GraphRelationship } from "./types";

export const getGraph = (models: GraphModel[], relationships: GraphRelationship[]) => {
  const graph = new DepGraph({ circular: true });
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  if (!models || !relationships) {
    return { nodes, edges };
  }

  for (const model of models) {
    graph.addNode(String(model.id));
    nodes.push({
      id: String(model.id),
      type: model.type,
      style: { width: NODE_WIDTH, height: NODE_HEIGHT },
      data: { label: model.name },
      position: { x: 0, y: 0 },
    });
  }

  for (const relationship of relationships) {
    const edge = {
      id: nanoid(),
      type: "custom",
      source: relationship.from,
      target: relationship.to,
      markerEnd: "",
      markerStart: "",
      data: {
        from: relationship.from,
        edgeId: relationship.edgeId,
        reverseEdgeId: relationship.reverseEdgeId,
        cardinality: relationship.cardinality,
      },
    };

    graph.addDependency(relationship.from, relationship.to);
    edges.push(edge);
  }

  return { nodes, edges };
};

export const getParams = () => {
  const params = new URL(window.location.toString()).searchParams;
  const source = params.get("source");
  const parent = params.get("parent");
  return {
    source,
    parent,
    queryString: `?source=${source}${parent ? `&parent=${parent}` : ""}`,
  };
};

export const OPTIONS: { type: SchemaModelType; label: string; description: string; icon: string }[] = [
  {
    icon: relatedSrc,
    type: SchemaModelType.Related,
    label: "Create a related model",
    description: "I want to filter based on a many-to-one relationship with another model",
  },
  {
    icon: eventSrc,
    type: SchemaModelType.Event,
    label: "Create a related event",
    description: "I want to filter on actions that this user has taken",
  },
];

export const schemaIcons: Record<SchemaModelType, string> = {
  [SchemaModelType.Parent]: parentIcon,
  [SchemaModelType.Event]: eventIcon,
  [SchemaModelType.Related]: relatedIcon,
};

export const getConnectedNodes = (startingNodeId: string, nodes: Node[], edges: Edge[]): string[] => {
  const leftNodes = findLeftNodes(startingNodeId, nodes, edges);
  const rightNodes = findRightNodes(startingNodeId, nodes, edges);

  return Array.from(new Set([...leftNodes, ...rightNodes]));
};

const findLeftNodes = (startingNodeId: string, nodes: Node[], edges: Edge[]): string[] => {
  const visitedNodeIds: Set<string> = new Set();

  function traverse(nodeId: string) {
    if (visitedNodeIds.has(nodeId)) {
      return;
    }

    const currentNode = nodes.find((node) => node.id === nodeId);
    if (currentNode) {
      visitedNodeIds.add(currentNode.id);
      if (currentNode.data.type !== SchemaModelType.Parent) {
        // Find edges with the current node as the target
        const targetEdges = edges.filter((edge) => edge.target === nodeId);

        for (const edge of targetEdges) {
          traverse(edge.source);
        }
      }
    }
  }

  traverse(startingNodeId);
  return Array.from(visitedNodeIds);
};

const findRightNodes = (startingNodeId: string, nodes: Node[], edges: Edge[]): string[] => {
  const visitedNodeIds: Set<string> = new Set();

  function traverse(nodeId: string) {
    if (visitedNodeIds.has(nodeId)) {
      return;
    }

    const currentNode = nodes.find((node) => node.id === nodeId);

    if (currentNode) {
      visitedNodeIds.add(currentNode.id);

      const targetEdges = edges.filter((edge) => edge.source === nodeId);

      for (const edge of targetEdges) {
        traverse(edge.target);
      }
    }
  }

  traverse(startingNodeId);
  return Array.from(visitedNodeIds);
};

export const NODE_WIDTH = 232;
export const NODE_HEIGHT = 72;
export const NODE_SPACING = 150;
